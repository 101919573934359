
img.image-is256{
    height: 256px;
    width: 256px;
    display: block;
    position: relative;
}

.p-align-centre {
    padding: 3%;
    text-align: center;
}

.p-vcentre {
    top: 50%;
    position: relative;
    transform: translateY(-50%);
}

.card-multiple {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-multiple-contents { padding-top: 15px; }

@media screen and (max-width: 850px) {
    .card-multiple > img {
        padding-top: 5px;
    }
}

@media screen and (max-width: 768px) {
    img.image-is256{ margin: 0 auto !important; }
    p {text-align: center;}
    .p-vcentre {transform: initial;}
}